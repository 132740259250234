import * as React from 'react'
import { useI18n, useTranslation, useTranslationString } from '../../../lib/context/i18n'
import { richtextToText } from '../../../lib/utils/richtextToText'
import { ProductBlok } from '../../../types'
import { Button } from '../../UI/Button'
import { IconList } from '../../UI/IconList'
import CustomLink from '../../CustomLink'
import { IconListItem } from '../../UI/IconList/types'
import { TranslationKey } from '../../../lib/context/i18n/interfaces'
import {
  DescriptionText,
  DescriptionTextCta,
  HeaderRow,
  HeaderRowLeft,
  HeaderRowRight,
  InfoRow,
  InfoRowLeft,
  NavButtons,
  ProductHeadline,
  ProductIcon,
  ProductName,
  ViewMoreButtonWrapper,
  Wrapper,
} from './styled'
import EmailSubmit from '../../../modules/EmailSubmit'
import { Select } from '../../UI/Select'
import styled from 'styled-components'
import { downloads, getPackageOptionName } from '../../../modules/CodeSamples/dataAssignment'
import { Divider } from '../../OnboardingWizzard/styled'
import { StyledLegalText } from '../../../modules/TeaserTitle/TitleTeaserBlok'
import { Icon } from '../../UI'
import { Link } from 'gatsby'
import { useProductKitDownload } from '../../../lib/hooks/useDownloadProductKit'
import { navigate } from 'gatsby'
import { isServer } from '../../../lib/utils'
import posthog from 'posthog-js'

export interface ProductHeaderProps {
  blok: ProductBlok
}

const StyledSelect = styled(Select)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1.5px solid var(--Black-black-6-S10-L94, #F0F0EF);
  width: 100%;
  min-width: 20rem;
`

const StyledLink = styled(Link)`
  color: var(--black-black-1, #0d0d0d);
  font-feature-settings: 'salt' on;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
`

const StyledLinkNoUnderline = styled(Link)`
  color: var(--c-theme-2);
  font-feature-settings: 'salt' on;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: none;
`


export const IndexSdkVariant = ({ blok }: ProductHeaderProps) => {
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)
  const [productName, setProductName] = React.useState('')
  const productVariants = blok.productVariant
  const operationSystems = blok.operationsSystems
  const { getTranslatedUrlFromLink } = useI18n()
  const currentUrl = !isServer() ? window?.location?.pathname : ''

  const isExtractPage = currentUrl.includes('extract');
  const t = useTranslation()

  React.useEffect(() => {
    if (downloads.kits && downloads.kits.length > 0) {
      setProductName(downloads?.kits[0]?.description)
    }
  }, [])

  const toggleDescription = React.useCallback(() => {
    setDescriptionOpen(val => !val)
  }, [])

  const { openDownload } = useProductKitDownload()

  const descriptionString = blok.description && richtextToText(blok.description)
  const additionalDescription = blok.additionalDescription;

  const showMore = blok.shortDescription.length > 320

  const description = showMore
    ? descriptionOpen
      ? descriptionString
      : `${descriptionString?.slice(0, 320)}...`
    : descriptionString

  const osIcons = operationSystems?.map(os => ({
    icon: os.content.icon,
    name: os.content.name,
  }))

  const variantIcons = productVariants?.map(variant => ({
    icon: variant.content.icon,
    name: variant.content.name,
  }))

  const downloadVariants = React.useMemo(() => {
    return downloads?.kits?.map(kit => ({
      name: getPackageOptionName(kit.description),
      value: kit.description,
    }))
  }, [downloads])

  const handleDownload = () => {
    openDownload({ productName })
    navigate('/products/download?package=' + productName)
  }


  const [requireEmailGroup, setrequireEmailGroup] = React.useState(false)



  return (
    <Wrapper>
      <HeaderRow style={{
        display: 'flex',
        flexDirection: blok?.noImage ? 'column' : 'row',
        gap: '1rem',
        width: '100%',
        marginBottom: '4rem',
      }}>
        <HeaderRowLeft style={{
          textAlign: blok?.noImage ? 'center' : 'left',
        }}>
          {/* <ComingSoonWrapper> */}
          <ProductName>{blok.productName}</ProductName>
          {/* {blok.comingSoon && <ComingSoonChip>Coming soon</ComingSoonChip>} */}
          {/* </ComingSoonWrapper> */}
          <ProductHeadline>{blok.headline}</ProductHeadline>
          <InfoRow>
            <InfoRowLeft>
              <DescriptionText>{description}</DescriptionText>
              {<DescriptionTextCta>
                {requireEmailGroup ? '👇️ Get the free PDF SDK Experience Edition directly to your inbox.' : additionalDescription}
              </DescriptionTextCta>}
              {blok.comingSoon && requireEmailGroup && (
                <div>
                  <NavButtons style={{
                    display: 'flex',
                    justifyContent: blok?.noImage ? 'center' : 'left',
                    alignItems: 'flex-start',
                  }}>
                    <EmailSubmit productCode={blok.productCode} placeholder={blok.inputPlaceholder} ctaLabel={blok.ctaLabel} />
                    {blok.buttons[1] && (
                      <Button
                        as={CustomLink}
                        $variant="secondary"
                        to={getTranslatedUrlFromLink(blok.buttons[1]?.link)}
                        iconRight={!!blok.buttons[1]?.link_target && 'link-external'}
                        style={
                          {
                            marginTop: requireEmailGroup ? '10px' : '0',
                          }
                        }
                      >
                        {blok.buttons[1]?.button_name}
                      </Button>
                    )}
                  </NavButtons>
                </div>
              )
              }
              {blok.comingSoon && (
                <StyledLegalText style={{
                  display: 'flex',
                  justifyContent: blok?.noImage ? 'center' : 'left',
                  alignItems: requireEmailGroup ? 'flex-start' : undefined,
                  minWidth: "460px"
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: blok?.noImage ? 'center' : 'left',
                  }}>
                    <IconList icons={[...osIcons] as IconListItem[]} />
                  </div>
                  <Divider />
                  {!requireEmailGroup && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                    <Icon name="key" size={16} />
                    {'Need a full license key?'} <StyledLink to="/contact">
                      {' Contact us'}
                    </StyledLink>
                    <Icon name="arrow-right" size={16} />
                  </div>}
                  {requireEmailGroup && (
                    <LegalCopy />
                  )}
                </StyledLegalText>
              )}
              {showMore && (
                <ViewMoreButtonWrapper>
                  <Button
                    onClick={toggleDescription}
                    $variant="secondary"
                    $size="small"
                    iconRight={descriptionOpen ? 'minus' : 'plus'}
                    $ignoreTheme={true}
                  >
                    {descriptionOpen
                      ? useTranslationString(TranslationKey.general_viewLess)
                      : useTranslationString(TranslationKey.general_viewMore)}
                  </Button>
                </ViewMoreButtonWrapper>
              )}
            </InfoRowLeft>
          </InfoRow>

          {/* blok?.noImage is used for these coming soon pages */}
          {blok.comingSoon ?
            <NavButtons style={{
              display: 'flex',
              justifyContent: blok?.noImage ? 'center' : 'left',
              alignItems: 'flex-start'
            }}>
              {!requireEmailGroup && blok.buttons && blok.buttons.map((button, index) => (
                <Button
                  key={index}
                  as={CustomLink}
                  $variant={index === 0 ? 'primary' : 'secondary'}
                  to={getTranslatedUrlFromLink(button.link)}
                  iconRight={!!button.link_target && 'link-external'}
                >
                  {button.button_name}
                </Button>
              ))
              }

            </NavButtons>
            :
            <NavButtons style={{
              display: 'flex',
              justifyContent: blok?.noImage ? 'center' : 'left',
              alignItems: 'flex-start',
              width: '100%',
            }}>
              {

                requireEmailGroup ? (
                  <EmailSubmit productCode={blok.productCode} placeholder={blok.inputPlaceholder} ctaLabel={blok.ctaLabel} />
                ) : (
                  <>
                    <StyledSelect
                      label={t('Pdftools SDK package')}
                      onChange={e => setProductName(e.target.value)}
                      value={productName}
                      variant="light"
                    >
                      {downloadVariants?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </StyledSelect>
                    <Button
                      $variant="primary"
                      iconRight={'download'}
                      onClick={() => {
                        handleDownload()
                      }}
                    >
                      {blok.buttons[0]?.button_name}
                    </Button>
                  </>
                )}
              {blok.buttons[1] && (
                <Button
                  as={CustomLink}
                  $variant="secondary"
                  to={getTranslatedUrlFromLink(blok.buttons[1]?.link)}
                  iconRight={!!blok.buttons[1]?.link_target && 'link-external'}
                  style={
                    {
                      marginTop: requireEmailGroup ? '10px' : '0',
                    }
                  }
                >
                  {blok.buttons[1]?.button_name}
                </Button>
              )}
            </NavButtons>
          }
          {!blok.comingSoon && !requireEmailGroup ? (
            <DescriptionTextCta style={{
              fontStyle: 'italic',
            }}>
              {'Also available via'} <StyledLinkNoUnderline to="https://www.nuget.org/packages/PdfTools/" target='_blank' rel='noreferrer noopener'>
                {' Nuget (.NET)'}
              </StyledLinkNoUnderline>
              {' and'}
              <StyledLinkNoUnderline to="https://central.sonatype.com/artifact/com.pdftools/pdftools-sdk/overview" target='_blank' rel='noreferrer noopener'>
                {' Maven (Java)'}
              </StyledLinkNoUnderline>
              {' package managers'}
            </DescriptionTextCta>
          ) : <></>}
          <StyledLegalText style={{
            display: 'flex',
            justifyContent: blok?.noImage ? 'center' : 'left',
            alignItems: requireEmailGroup ? 'flex-start' : undefined,
            minWidth: "460px"
          }}>
            {!blok.comingSoon && <>
              <IconList icons={[...osIcons] as IconListItem[]} />
              <Divider />
              {!requireEmailGroup && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                <Icon name="key" size={16} />
                {'Need a full license key?'} <StyledLink to="/contact">
                  {' Contact us'}
                </StyledLink>
                <Icon name="arrow-right" size={16} />
              </div>}
              {requireEmailGroup && (
                <LegalCopy />
              )}
            </>}
          </StyledLegalText>
        </HeaderRowLeft>
        <HeaderRowRight>
          <ProductIcon src={blok.image.filename} alt={blok.image.alt} />
        </HeaderRowRight>
      </HeaderRow>
    </Wrapper >
  )
}

const LegalCopy = () => {

  return (
    <LegalCopyContainer style={{
      display: 'flex', alignItems: 'center', gap: '0.3em', fontStyle: 'italic',
      whiteSpace: 'nowrap',
      // flexWrap: 'wrap',
      flexWrap: 'nowrap',
    }}>
      <span>{'By signing up, you agree to our '}</span>
      <span>
        <StyledLink to="/license-agreement/">
          {'License agreement'}
        </StyledLink> {' and '}
        <StyledLink to="/privacy-policy/">
          {'Privacy policy'}
        </StyledLink>
      </span>
    </LegalCopyContainer>
  )
}


export const LegalCopyContainer = styled.div`

  @media screen and (max-width: 576px) {
    flex-wrap: wrap !important;
  }
`
