import { useEffect, useRef } from 'react'
import getFieldsFromStory from '../../utils/getFieldsFromStory'
import { DataLayerEvents, DataLayerVariables } from '../../context/TrackingContext/interfaces'
import { track } from '../../utils/tracking'
import { SbEditableBlok } from '@storyblok/storyblok-editable'
import { snakeCase } from 'lodash'
import { useTrackingContext } from '../../context/TrackingContext'

export interface ISbPageFields {
  productGroup?: string
  productName?: string
  pageCategory?: string
}

const useDLPageView = (story: SbEditableBlok) => {
  const isLoaded = useRef<boolean>(false)
  const fields = getFieldsFromStory<ISbPageFields>(story)
  const { setTrackingVariables, triggerEvent } = useTrackingContext()

  useEffect(() => {
    if (!isLoaded.current) {
      setTimeout(() => {
        setTrackingVariables({
          [DataLayerVariables.EVENT]: DataLayerEvents.PAGE_VIEW,
          [DataLayerVariables.PRODUCT_CATEGORY]: snakeCase(fields.productGroup) || 'NA',
          [DataLayerVariables.PRODUCT_NAME]: snakeCase(fields.productName) || 'NA',
          [DataLayerVariables.PAGE_CATEGORY]: fields.pageCategory || 'NA',
        })

        triggerEvent(DataLayerEvents.PAGE_VIEW)
      }, 50)
      isLoaded.current = true
    }
  }, [track])
}

export default useDLPageView
